import React from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import { mergeStyles, DefaultPalette } from '@fluentui/react/lib/Styling';
//import logo from './logo.svg';
import './App.css';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '960px',
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};

const topStackStyles: Partial<IStackStyles> = {
  root: {
    background: DefaultPalette.themeTertiary,
  },
};

export const App: React.FunctionComponent = () => {
  return (
    <Stack >
      <Stack horizontalAlign="start" styles={topStackStyles}>qqq</Stack>
      <Stack horizontal>
        <Stack horizontalAlign="start" styles={topStackStyles}>menu</Stack>
      
      <Stack horizontalAlign="start" verticalAlign="center" verticalFill styles={stackStyles} tokens={stackTokens}>
        {/* whenClicked is a property not an event, per se. 
        <img className="App-logo" src={logo} alt="logo" />
        */}
        <Text variant="xxLarge" styles={boldStyle}>
          Welcome to your Fluent UI app
        </Text>
        <Text variant="large">For a guide on how to customize this project, check out the Fluent UI documentation.</Text>
        <Text variant="large" styles={boldStyle}>
          Essential links
        </Text>
        <Stack horizontal tokens={stackTokens} horizontalAlign="center">
          <Link href="https://developer.microsoft.com/en-us/fluentui#/get-started/web">Docs</Link>
          <Link href="https://stackoverflow.com/questions/tagged/office-ui-fabric">Stack Overflow</Link>
          <Link href="https://github.com/microsoft/fluentui/">Github</Link>
          <Link href="https://twitter.com/fluentui">Twitter</Link>
        </Stack>
        <Text variant="large" styles={boldStyle}>
          Design system
        </Text>
        <Stack horizontal tokens={stackTokens} horizontalAlign="center">
          <Link href="https://developer.microsoft.com/en-us/fluentui#/styles/web/icons">Icons</Link>
          <Link href="https://developer.microsoft.com/en-us/fluentui#/styles/web">Styles</Link>
          <Link href="https://aka.ms/themedesigner">Theme designer</Link>
        </Stack>
      </Stack>
      </Stack>
    </Stack>
  );
};
